import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'dhmePickOrderManagementAssemblyHalls',
      'dhmePickOrderManagementStorageLocations',
    ]),
    locations() {
      return [
        ...this.dhmePickOrderManagementAssemblyHalls,
        ...this.dhmePickOrderManagementStorageLocations,
      ];
    },
  },
  methods: {
    isAssemblyHall(item) {
      return (
        this.dhmePickOrderManagementAssemblyHalls.findIndex(
          (item2) => item2 === item
        ) >= 0
      );
    },
  },
};
