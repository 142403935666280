<template>
  <div
    class="d-flex justify-center mx-10 my-5 overflow-auto"
    style="max-width: 500px"
  >
    <div
      v-for="column in levelColumns"
      :key="column"
      class="d-flex flex-column level-column white"
    >
      <div
        v-for="item in levelColumnRows(column)"
        :key="item.id"
        class="level-row d-flex align-center justify-center ant-border"
        :class="{
          'level-row-contains-item': containsStoredElement(item),
          'level-row-selected': selectedBox?.id === item.id,
        }"
        @click="selectStorageBox(item)"
      >
        <v-icon
          v-if="containsStoredElement(item)"
          color="white"
          dense
          class="mr-2"
          >mdi-alert-outline</v-icon
        >
        {{ locationLevel + column + item.row }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StorageLocationLevelLayout',
  props: {
    storageLocation: {
      type: Object,
      required: true,
    },
    locationLevel: {
      type: Number,
      required: true,
    },
    storageBoxes: {
      type: Array,
      required: true,
    },
    selectedBox: {
      type: Object,
      required: false,
    },
    levelItems: {
      type: Array,
      required: true,
    },
  },
  computed: {
    levelColumns() {
      return [
        ...new Set(
          this.storageBoxes
            .filter(
              (item) =>
                item.storage_location === this.storageLocation.id &&
                item.level === this.locationLevel
            )
            .map((item) => item.column)
        ),
      ].sort((a, b) => a.localeCompare(b));
    },
  },
  methods: {
    selectStorageBox(item) {
      this.$emit('selectStorageBox', item);
    },
    containsStoredElement(item) {
      return this.levelItems.findIndex((x) => x.storage_box === item.id) !== -1;
    },
    levelColumnRows(column) {
      return this.storageBoxes
        .filter(
          (item) =>
            item.storage_location === this.storageLocation.id &&
            item.level === this.locationLevel &&
            item.column === column
        )
        .sort((a, b) => a.row - b.row);
    },
  },
};
</script>

<style scoped lang="scss">
.level-column {
  width: 100px;
  .level-row {
    height: 70px;
    width: inherit;
  }
}

.level-row-contains-item {
  background-color: var(--v-warning-base);
  color: white;
}

.level-row-selected {
  animation: select-animation 1s infinite;
}

@keyframes select-animation {
  0% {
    background: white;
  }
  50% {
    background: #4d90fe;
  }
  100% {
    background: white;
  }
}
</style>
