<template>
  <v-dialog
    v-model="showDialog"
    max-width="600"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="justify-center text-uppercase headline">
        Create pick order
      </v-card-title>
      <v-divider />
      <div class="px-10 pb-5">
        <v-form ref="pick-order-form">
          <ant-input label="Type">
            <template #input-field>
              <v-select
                v-model="pickOrderItem.options.custom_1"
                placeholder="Type"
                hide-details
                :items="['Portalen', 'Wanden', 'Vloeren', 'Modules']"
                dense
                filled
                single-line
                type="text"
                :rules="[rules.required]"
              />
            </template>
          </ant-input>

          <ant-input label="Project">
            <template #input-field>
              <v-select
                v-model="pickOrderItem.project"
                placeholder="Project"
                hide-details
                dense
                filled
                :items="projects"
                single-line
                type="text"
                :rules="[rules.required]"
                item-text="name"
                item-value="id"
              />
            </template>
          </ant-input>

          <ant-input label="Date">
            <template #input-field>
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="pickOrderItem.due"
                    placeholder="Date"
                    prepend-inner-icon="mdi-calendar"
                    clearable
                    :rules="[rules.required]"
                    readonly
                    hide-details
                    dense
                    filled
                    single-line
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="pickOrderItem.due"
                  :min="now"
                  @input="dateMenu = false"
                />
              </v-menu>
            </template>
          </ant-input>

          <ant-input label="Id">
            <template #input-field>
              <v-combobox
                v-model="pickOrderItem.options.custom_2"
                placeholder="ID"
                hide-details
                dense
                filled
                single-line
                multiple
                small-chips
                deletable-chips
                type="text"
                :rules="[rules.required]"
              />
            </template>
          </ant-input>

          <div class="d-flex">
            <ant-input label="From">
              <template #input-field>
                <v-select
                  v-model="pickOrderItem.options.custom_3"
                  placeholder="From"
                  hide-details
                  :items="locations"
                  dense
                  filled
                  single-line
                  item-value="id"
                  :rules="[rules.required]"
                  append-outer-icon="mdi-arrow-expand-right"
                >
                  <template #selection="{ item }">
                    <v-icon class="mr-2">
                      {{
                        isAssemblyHall(item)
                          ? 'mdi-warehouse'
                          : 'mdi-file-table-box'
                      }}
                    </v-icon>
                    {{ isAssemblyHall(item) ? item.hall : item.name }}
                  </template>
                  <template #item="{ item }">
                    <div class="d-flex align-center">
                      <v-icon class="mr-2">
                        {{
                          isAssemblyHall(item)
                            ? 'mdi-warehouse'
                            : 'mdi-file-table-box'
                        }}
                      </v-icon>
                      {{ isAssemblyHall(item) ? item.hall : item.name }}
                    </div>
                  </template>
                </v-select>
              </template>
            </ant-input>
            <v-spacer />

            <ant-input label="To">
              <template #input-field>
                <v-select
                  v-model="pickOrderItem.options.custom_4"
                  placeholder="To"
                  hide-details
                  :items="locations"
                  dense
                  filled
                  single-line
                  item-value="id"
                  :rules="[rules.required]"
                >
                  <template #selection="{ item }">
                    <v-icon class="mr-2">
                      {{
                        isAssemblyHall(item)
                          ? 'mdi-warehouse'
                          : 'mdi-file-table-box'
                      }}
                    </v-icon>
                    {{ isAssemblyHall(item) ? item.hall : item.name }}
                  </template>
                  <template #item="{ item }">
                    <div class="d-flex align-center">
                      <v-icon class="mr-2">
                        {{
                          isAssemblyHall(item)
                            ? 'mdi-warehouse'
                            : 'mdi-file-table-box'
                        }}
                      </v-icon>
                      {{ isAssemblyHall(item) ? item.hall : item.name }}
                    </div>
                  </template>
                </v-select>
              </template>
            </ant-input>
          </div>

          <ant-input label="Description">
            <template #input-field>
              <v-text-field
                v-model="pickOrderItem.description"
                placeholder="Description"
                hide-details
                :items="locations"
                dense
                filled
                single-line
                :rules="[rules.required]"
              />
            </template>
          </ant-input>
        </v-form>
      </div>

      <v-card-actions class="ant-dialog-actions-bg ant-border-top">
        <v-spacer />
        <v-btn
          color="error"
          text
          small
          :disabled="$wait.is('dhme.pick-order-management.create')"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          small
          :loading="$wait.is('dhme.pick-order-management.create')"
          @click="createPickOrder"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AntInput from '@/components/AntInput.vue';
import moment from 'moment';
import { executeCustomModuleCall } from '@/services/api/module.api';
import { mapGetters } from 'vuex';
import { DHME_M_PICK_ORDER_MANAGEMENT } from '@/modules/modules';
import PickOrderManagementMixin from '@/components/Modules/Daiwa-House-Modular-Europe/PickOrderAndYardManagement/PickOrderManagementMixin';
import InputRulesMixin from '@/Mixins/InputRulesMixin';

export default {
  name: 'AddPickOrderDialog',
  components: { AntInput },
  mixins: [PickOrderManagementMixin, InputRulesMixin],
  props: {
    displayed: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      pickOrderItem: {
        options: {
          type: 'dhme-pickorder',
        },
      },
      dateMenu: false,
      now: moment().format('YYYY-MM-DD'),
      projects: [],
    };
  },
  computed: {
    ...mapGetters([
      'authenticatedUser',
      'project',
      'dhmePickOrderManagementAssemblyHalls',
      'dhmePickOrderManagementStorageLocations',
    ]),
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_M_PICK_ORDER_MANAGEMENT
      ).id;
    },
    showDialog() {
      return this.displayed;
    },
  },
  async mounted() {
    this.projects = await executeCustomModuleCall(
      this.project.id,
      this.moduleId,
      'fetchProjects'
    );
  },
  methods: {
    closeDialog() {
      this.pickOrderItem = Object.assign(
        {},
        {
          options: {
            type: 'dhme-pickorder',
          },
        }
      );
      this.$refs['pick-order-form'].reset();
      this.$emit('closeDialog');
    },
    async createPickOrder() {
      if (this.$refs['pick-order-form'].validate()) {
        this.pickOrderItem.license = this.project.license;
        this.pickOrderItem.project = this.project.id;
        this.pickOrderItem.assigned_to = this.authenticatedUser.id;
        this.pickOrderItem.title = `Pickorder: ${this.pickOrderItem.options.custom_2}`;
        this.pickOrderItem.priority = 'normal';

        let tasks = [];

        this.pickOrderItem.options.custom_2.forEach((id) => {
          let object = JSON.parse(JSON.stringify(this.pickOrderItem));
          object.license = this.project.license;
          object.project = this.project.id;
          object.assigned_to = this.authenticatedUser.id;
          object.title = `Pickorder: ${id}`;
          object.priority = 'normal';
          object.options.custom_2 = id;
          tasks.push(object);
        });

        try {
          await this.$store.dispatch('createPickOrderTasks', tasks);
          this.closeDialog();
          await this.$store.dispatch('updatePickOrderTasks', {
            moduleId: this.moduleId,
          });
        } catch (e) {}
      }
    },
  },
};
</script>

<style scoped></style>
